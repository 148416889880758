import { graphql, Link } from "gatsby"
import { OutboundLink } from "gatsby-plugin-google-gtag"
import _ from "lodash"
import log from "loglevel"
import React from "react"
import ButtonList from "../components/button-list"
import FAQs from "../components/faqs"
import ImageGridWithZoom from "../components/image-grid-zoom"
import Layout from "../components/layout"
import Notes from "../components/notes"

const BSB = ({ data, pageContext }) => {
  const product = data.product.nodes[0] || {}
  const category = pageContext.category
  const subcategories = pageContext.category.subcategories
  const selectedSubcategoryId = pageContext.subcategoryId
  const faqs = data.faqs.nodes

  product.image = data.images.edges.length && data.images.edges[0].node.parent

  product.clearanceImages = data.clearanceImages.edges.map(
    edge => (edge.node || {}).parent
  )

  product.pipingImages = data.pipingImages.edges.map(
    edge => (edge.node || {}).parent
  )

  product.models.forEach(model => {
    if (model.submittalSheet) {
      model.submittalSheet.path = (
        (
          data.submittalSheet.edges.find(
            edge => edge.node.name === model.modelNumber
          ) || {}
        ).node || {}
      ).publicURL
    }
  })

  log.info(product, data, faqs, pageContext)

  return (
    <Layout title={product.name} navItems={pageContext.categories || []}>
      <div className="uk-section-xsmall">
        <div className="uk-container uk-container-large">
          <ul className="uk-breadcrumb">
            <li>
              <span>{category.name}</span>
            </li>
            <li>
              <Link
                to={`/category/${category._id}/sub/${selectedSubcategoryId}`}
              >
                {product.category}
              </Link>
            </li>
            <li>
              <span>{product.productSeries}</span>
            </li>
          </ul>
          <div className="uk-section-xsmall">
            <div className="uk-container uk-container-large uk-text-center">
              {/* <h2>{category.name}</h2> */}
              <ButtonList
                items={subcategories}
                parentId={category._id}
                selectedItemId={selectedSubcategoryId}
              ></ButtonList>
            </div>
          </div>
          <div className="product uk-section-xsmall">
            <h1 className="uk-text-center">{product.name}</h1>
            <p className="uk-text-center">
              <span className="uk-text-bold">{product.capacityText}</span>
              &nbsp;&nbsp;|&nbsp;&nbsp;
              <span className="uk-text-bold">Model: </span>
              {product.productSeries}
            </p>
          </div>
          <ImageGridWithZoom
            images={[product.image]}
            title={""}
            maxWidth={"400px"}
          ></ImageGridWithZoom>

          <div className="uk-section-xsmall">
            <h3 className="uk-text-bold uk-text-center"></h3>
            <div className="uk-overflow-auto">
              <table className="uk-table uk-table-striped uk-text-center uk-table-small">
                <thead className="thead-primary uk-text-center">
                  <tr>
                    <th>Model Number</th>
                    <th>Number of Ports</th>
                    <th>Nominal Size Per Port (MBH)</th>
                    <th>Operating Sound db(A)</th>
                    <th>Max Sound dB(A)</th>
                    <th>Dimensions (HxWxD)</th>
                    <th>Voltage</th>
                    <th>MCA (A)</th>
                    <th>MOP (A)</th>
                  </tr>
                </thead>
                <tbody>
                  {product.models.map((model, i) => (
                    <tr key={`main_table_tr_${i}`}>
                      <td>{model.modelNumber}</td>
                      <td>{model.ports}</td>
                      <td>{model.capacity}</td>
                      <td>{model.operatingSound}</td>
                      <td>{model.maxSound}</td>
                      <td className="text-no-wrap">{model.dimensions}</td>
                      <td className="text-no-wrap">{model.powerSupply}</td>
                      <td>{model.MCA}</td>
                      <td>{model.MOP}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
            {(product.notes || []).length ? (
              <div className="uk-margin-top">
                <h5>Notes</h5>
                <div className="uk-text-meta">
                  <ul>
                    {product.notes.map((feature, i) => (
                      <li key={`note_${i}`}>{feature}</li>
                    ))}
                  </ul>
                </div>
              </div>
            ) : (
              <></>
            )}
          </div>

          <Notes title={"Features"} items={product.features} />

          <ImageGridWithZoom
            images={product.clearanceImages}
            title={"Installation Clearances"}
          ></ImageGridWithZoom>

          <ImageGridWithZoom
            images={product.pipingImages}
            title={"Piping Length"}
          ></ImageGridWithZoom>

          <div className="uk-section-xsmall">
            <h3 className="uk-text-bold uk-text-center">
              Submittal Data Sheets
            </h3>
            <div className="uk-overflow-auto">
              <table
                className="uk-table uk-table-striped  uk-table-small uk-margin-auto"
                style={{ maxWidth: "500px" }}
              >
                <thead className="thead-primary">
                  <tr>
                    <th></th>
                    {_.uniq(product.models.map(model => model.ports)).map(
                      (ports, i) => (
                        <th key={ports as string}>{ports} Ports</th>
                      )
                    )}
                  </tr>
                </thead>
                <tbody>
                  {_.uniq(product.models.map(model => model.capacity)).map(
                    (capacity, i) => {
                      const ports = _.uniq(
                        product.models.map(model => model.ports)
                      )
                      return (
                        <tr key={`submittalSheets_row_${i}`}>
                          <td
                            className="uk-text-bold text-dark"
                            style={{ width: `${100 / (ports.length + 1)}%` }}
                          >
                            {capacity}
                          </td>
                          {ports.map((port, j) => {
                            const model = product.models.find(
                              _model =>
                                _model.ports === port &&
                                _model.capacity === capacity &&
                                _model.submittalSheet &&
                                _model.submittalSheet.path
                            )
                            return (
                              <td
                                key={`submittalSheets_col_${j}`}
                                style={{
                                  width: `${100 / (ports.length + 1)}%`,
                                }}
                              >
                                {((model || {}).submittalSheet || {}).path ? (
                                  <OutboundLink
                                    href={model.submittalSheet.path}
                                    target="_blank"
                                  >
                                    {model.modelNumber}
                                  </OutboundLink>
                                ) : (
                                  ""
                                )}
                              </td>
                            )
                          })}
                        </tr>
                      )
                    }
                  )}
                </tbody>
              </table>
            </div>
          </div>
          <FAQs items={faqs} productSeries={product.productSeries}></FAQs>
        </div>
      </div>
    </Layout>
  )
}

export const query = graphql`
  query BSBProductAndImage(
    $productId: String
    $imageId: String
    $categoryRegex: String
    $subcategoryRegex: String
    $clearanceRegex: String
    $pipingRegex: String
    $submittalSheetIds: [String]
  ) {
    images: allImageSharp(filter: { original: { src: { regex: $imageId } } }) {
      edges {
        node {
          id
          parent {
            ... on File {
              id
              name
            }
            ...fluidImage
          }
        }
      }
    }
    clearanceImages: allImageSharp(
      filter: { original: { src: { regex: $clearanceRegex } } }
    ) {
      edges {
        node {
          id
          parent {
            ... on File {
              id
              name
            }
            ...fluidImage
          }
        }
      }
    }
    pipingImages: allImageSharp(
      filter: { original: { src: { regex: $pipingRegex } } }
    ) {
      edges {
        node {
          id
          parent {
            ... on File {
              id
              name
            }
            ...fluidImage
          }
        }
      }
    }
    submittalSheet: allFile(
      filter: { extension: { eq: "pdf" }, name: { in: $submittalSheetIds } }
    ) {
      edges {
        node {
          publicURL
          name
        }
      }
    }
    faqs: allFaqsJson(
      filter: {
        categoryId: { regex: $categoryRegex }
        subcategoryId: { regex: $subcategoryRegex }
      }
    ) {
      ...faqsForCategoryAndSubcategory
    }
    product: allProductsJson(filter: { _id: { eq: $productId } }) {
      nodes {
        _id
        category
        capacity
        capacityText
        depth
        height
        name
        productId
        productSeries
        features
        notes
        subcategory
        width
        models {
          capacity
          CFM
          MCA
          MCAMOP
          MOP
          dimensions
          cooling
          heating
          maxSound
          modelNumber
          moduleCapacity
          moduleType
          name
          operatingSound
          ports
          powerSupply
          productSeries
          availableUS
          availableCA
          estimatedHeating
          coolingEfficiency
          heatingEfficiency
          coolingEfficiencySEER
          heatingEfficiencyHSPF
          heatingEfficiencyEWT
          airflow
          waterflow
          weight
          energyStar
          submittalSheet {
            url
            fileName
            type
          }
        }
      }
    }
  }
`

export default BSB
