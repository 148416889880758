import PropTypes from "prop-types"
import React from "react"

import TitleWithCopyLink from "./title-copy-link"

const Notes = ({ title, items, id }) => {
  if (!items || !items.length) {
    return <></>
  }
  return (
    <div className="uk-padding-small notes-component">
      <TitleWithCopyLink title={title} id={id} type="h3" />
      <div className="uk-flex uk-flex-center">
        <ul className="uk-list uk-list-disc uk-text-left">
          {items.map((item, i) => (
            <li key={`${(title || "").toLowerCase()}_${i}`}>{item}</li>
          ))}
        </ul>
      </div>
    </div>
  )
}

Notes.propTypes = {
  items: PropTypes.array,
  title: PropTypes.string,
  id: PropTypes.string,
}

Notes.defaultProps = {
  title: "",
  items: [],
  id: "",
}

export default Notes
